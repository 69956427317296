
import { reactive, defineComponent, ref, onMounted, computed, watch } from 'vue'
import Button from '@/components/UI/Button.vue'
import Input from '@/components/UI/Input.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import ListingTable from '@/components/UI/ListingTable.vue'
import Pagination from '@/components/UI/Pagination.vue'
import { UsersActionTypes } from '@/store/users/actions'
import { leaseActionTypes } from '@/store/leaseCategories/actions'
import { carPolicyActionTypes } from '@/store/carPolicy/actions'
import MessageBar from '@/components/UI/MessageBar.vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { employeeDropdownsType } from '@/types'
import CurrencyFormat from '@/filters/CurrencyFormat'
import Loader from '@/components/UI/Loader.vue'
import _ from 'lodash'
import { USER_STATUS } from '@/composables/staticValues'
import getCompany from '@/mixins/getCompany'
export default defineComponent({
  name: 'Home',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const getAllUsers = computed(() => store.state.users.getAllUsers)
    const totalUsers = computed(() => store.state.users.totalUsers)
    const loader = computed(() => store.state.users.loader)
    const isFilters = ref(true)
    const carPolicies = computed(() => store.state.carPolicy.getAllCarPolicies)
    const leaseCategories = computed(() => store.state.leaseCategories.allLeaseCategories)
    const { compId, companyDetailState } = getCompany()
    const roles = computed(() => store.state.auth.currentUser?.roles)
    const getAllCarPolicies = computed(() =>
      store.state.carPolicy.getAllCarPolicies.map((item) => {
        return { title: item.policyTitle, id: item.id }
      })
    )
    const getAllLeaseCategories = computed(() =>
      store.state.leaseCategories.allLeaseCategories.map((item) => {
        return { title: item.title, amount: item.amount, id: item.id }
      })
    )
    const initializeRequest = (query) => {
      store.dispatch(UsersActionTypes.GET_ALLUSERS, { companyId: compId, query: query })
    }
    const initializeDropdownsRequests = async () => {
      await store.dispatch(carPolicyActionTypes.GET_ALLPOLICIES, { companyId: compId })
      await store.dispatch(leaseActionTypes.GET_ALLCATEGORIES, { companyId: compId })
    }
    const carPolicyArray: { value: employeeDropdownsType[] } = reactive({ value: [] })
    const leaseCategoryArray: { value: employeeDropdownsType[] } = reactive({ value: [] })
    const statusItemsArray: { value: Array<string> } = reactive({ value: [] })
    const getIdsOnly = (selectedArray) => {
      return selectedArray.value.map((item) => item.id)
    }
    watch(
      () => route.query,
      () => {
        if (route.name == 'Employees') {
          initializeRequest(route.query)
        }
      }
    )
    onMounted(async () => {
      await initializeRequest(route.query)
      await initializeDropdownsRequests()
      const getSelectedFilterItems = (queryParams, storeData) => {
        const ids = queryParams.toString().split(',')
        const selectedItems = storeData.filter((option) => {
          return ids.includes(option['id']?.toString())
        })
        return selectedItems
      }
      if (route.query.carPolicy) {
        carPolicyArray.value = getSelectedFilterItems(route.query.carPolicy, getAllCarPolicies.value)
      }
      if (route.query.leaseCategory) {
        leaseCategoryArray.value = getSelectedFilterItems(route.query.leaseCategory, getAllLeaseCategories.value)
      }
      if (route.query.userStatus) {
        const selectedUserStatus = route.query.userStatus.toString().replace(/['"]+/g, '').split(',')
        statusItemsArray.value = selectedUserStatus
      }
    })
    const searchBy = route.query.name ? ref(route.query.name) : ref('')
    const employeeHeadings = reactive([
      { label: 'Employee name', key: 'name' },
      { label: 'Number plate', key: 'licensePlate' },
      { label: 'Lease valid until', key: 'leaseValidUntil' },
      { label: 'Car policy', key: 'carPolicy' },
      { label: 'Lease category', key: 'leaseCategory' },
      { label: 'Status', key: 'userStatus' }
    ])
    const selectedCarPolicy = ref('_employees.carPolicy')
    watch(
      () => _.cloneDeep(carPolicyArray.value),
      (currentValue) => {
        const carPolicyIds = currentValue.map((item) => item.id)
        const query = Object.assign({}, route.query, { carPolicy: carPolicyIds.join(',') })
        if (!currentValue.length) {
          delete query.carPolicy
        }
        router.push({ query })
      }
    )
    watch(
      () => _.cloneDeep(leaseCategoryArray.value),
      (currentValue) => {
        const leaseCategoriesIds = currentValue.map((item) => item.id)
        const query = Object.assign({}, route.query, { leaseCategory: leaseCategoriesIds.join(',') })
        if (!currentValue.length) {
          delete query.leaseCategory
        }
        router.push({ query })
      }
    )
    watch(
      () => _.cloneDeep(statusItemsArray.value),
      (currentValue) => {
        const statusItems = currentValue.map((item) => `'${item}'`)
        const query = Object.assign({}, route.query, { userStatus: statusItems.join(',') })
        if (!currentValue.length) {
          delete query.userStatus
        }
        router.push({ query })
      }
    )
    const selectCarPolicy = (option) => {
      const findIndex = carPolicyArray.value.findIndex((item) => item.id === option['id'])
      if (findIndex === -1) {
        carPolicyArray.value.push({ title: option['title'], id: option['id'] })
      }
    }
    const removeCarPolicy = (item, index) => {
      if (carPolicyArray.value.indexOf(item) !== -1) {
        carPolicyArray.value.splice(index, 1)
      }
    }
    const statusItems = reactive([
      USER_STATUS.SELECTOR_AVAILABLE,
      USER_STATUS.CAR_ORDERED,
      USER_STATUS.ACTIVE_LEASE,
      USER_STATUS.DEACTIVATED_ACCOUNT,
      USER_STATUS.NO_LEASE_CAR
    ])
    const selectedLeaseCategory = ref('_employees.leaseCategory')
    const selectLeaseCategory = (option) => {
      const findIndex = leaseCategoryArray.value.findIndex((item) => item.id === option['id'])
      if (findIndex === -1) {
        leaseCategoryArray.value.push({
          title: option['title'],
          amount: option['amount'],
          id: option['id']
        })
      }
    }
    const removeLeaseCategory = (item, index) => {
      if (leaseCategoryArray.value.indexOf(item) !== -1) {
        leaseCategoryArray.value.splice(index, 1)
      }
    }
    const selectedStatus = ref('_employees.status')
    const selectStatus = (item) => {
      if (statusItemsArray.value.indexOf(item) === -1) {
        statusItemsArray.value.push(item)
      }
    }
    const removeStatusItems = (item, index) => {
      if (statusItemsArray.value.indexOf(item) !== -1) {
        statusItemsArray.value.splice(index, 1)
      }
    }
    const sortBy = (value) => {
      const query = Object.assign({}, route.query, { orderBy: value['orderBy'], order: value['order'] })
      router.push({ query })
    }
    const setPage = (pageNumber) => {
      const query = Object.assign({}, route.query, { page: pageNumber })
      router.push({ query })
    }
    const onInput = (value) => {
      searchBy.value = value
    }
    const onEnter = () => {
      const searchedTerm = (searchBy.value as string).trim().toLowerCase()
      const query: { name?: string } = Object.assign({}, route.query, { name: searchedTerm, page: 1 })
      if (!searchedTerm) {
        delete query.name
      }
      router.push({ query })
    }
    const openNewEmployee = () => {
      router.push({ name: 'Employee' })
    }
    const editEmployee = (employeeId) => {
      router.push({ name: 'Employee', params: { id: employeeId } })
    }
    return {
      searchBy,
      onInput,
      openNewEmployee,
      selectedCarPolicy,
      selectCarPolicy,
      selectedLeaseCategory,
      selectLeaseCategory,
      statusItems,
      selectedStatus,
      selectStatus,
      employeeHeadings,
      getAllUsers,
      route,
      getAllCarPolicies,
      getAllLeaseCategories,
      carPolicyArray,
      removeCarPolicy,
      leaseCategoryArray,
      CurrencyFormat,
      removeLeaseCategory,
      statusItemsArray,
      removeStatusItems,
      getIdsOnly,
      sortBy,
      totalUsers,
      setPage,
      loader,
      editEmployee,
      isFilters,
      onEnter,
      carPolicies,
      leaseCategories,
      roles,
      companyDetailState
    }
  },
  components: {
    Button,
    Input,
    Dropdown,
    ListingTable,
    Loader,
    Pagination,
    MessageBar
  }
})
